<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="top">
			<div style="position: relative;">
				<img class="left" :src="info.picUrl" alt="" />
				<span style="position: absolute;right: 0px;top: 0px;color: white;background: red;padding: 5px 10px;border-top-right-radius: 10px;border-bottom-left-radius: 10px;">{{statusArr[info.status]}}</span>
			</div>
            <div class="right">
                <div>
                    <div class="title">{{ info.name }}</div>
                    <div class="tips">
                        <div class="tip" v-if="info.type>=0">{{ options[info.type]?options[info.type].dictLabel:'' }}</div>
                        <div class="tip" v-if="info.isFree == 1">免费</div>
                    </div>
                    <div class="item">
                        <img src="@/assets/images/Frame-24.png" alt="" />
                        <!-- <span>预约人数：{{ info.applyCount || 0 }}人</span> -->
						<span>剩余{{info.maxCount-(info.applyCount||0)}}人，总预约{{ info.maxCount || 0 }}人</span>
                    </div>
                    <div class="item">
                        <img src="@/assets/images/Frame-25.png" alt="" />
                        <span>{{ info.libraryAddress }}</span>
                    </div>
                    <div class="item">
                        <img src="@/assets/images/Frame-26.png" alt="" />
                        <span>{{ info.phoneNumber }}</span>
                    </div>
                    <div class="item">
                        <img src="@/assets/images/Frame-27.png" alt="" />
                        <span>{{ info.address }}</span>
                    </div>
                    <div class="item">
                        <img src="@/assets/images/Frame-28.png" alt="" />
                        <span>{{ info.startTime }} - {{info.endTime}}</span>
                    </div>
                </div>
                <div class="butts">
					<div class="butt" @click="userActivity" v-if="info.userActivityId">报名成功</div>
                    <div :class="(info.status === 3 || (info.status === 2 && info.reservationWay === 0)) ? 'butt3' : 'butt'" @click="openInfoDialog" v-else>报名</div>
                    <!--                    <div class="butt">待审核</div>-->
                    <div @click="collection" class="butt1">
                        <img :src="!info.collectionId ? require('@/assets/images/Frame-39.png') : require('@/assets/images/Vector.png')" alt="" />
                        <span>{{ info.collectionId ? '已' : '' }}收藏</span>
                    </div>
                    <div @click="praise" class="butt1">
                        <img :src="!info.praiseId ? require('@/assets/images/Frame-40.png') : require('@/assets/images/Frame-50.png')" alt="" />
                        <span>{{ info.praiseId ? '已' : '' }}点赞</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="titles" v-if=" info.reminderMsg">温馨提示</div>
        <div class="details" v-if=" info.reminderMsg">{{ info.reminderMsg }}</div>
        <div class="titles">活动详情</div><!-- 
        <div class="details" v-html="info.activeDetail"></div> -->
		<el-tabs v-model="tabs">
			<el-tab-pane label="活动详情" name="0">
				<div class="details" v-html="info.activeDetail"></div>
			</el-tab-pane>
			<el-tab-pane label="活动报告" name="1">
				<div style="width: 90%;margin:0 auto;">
					<ul>
						<li v-for="(item,index) in reportList" :key="index">
							<div class="report-file">
								<a :href="item.fileUrl" target="_blank" :download="item.fileName">
									<img src="../../assets/images/Frame-9.png" />{{item.fileName}}
								</a>
							</div>
							<div class="report-info">
								<span>{{item.createBy}}</span> 
								<span>{{item.createTime}}</span>
							</div>
						</li>
					</ul>
				</div>
			</el-tab-pane>
			<el-tab-pane label="活动评价" name="2">
				<evaludate-list :id="$route.query.id" type="1"></evaludate-list>
			</el-tab-pane>
		</el-tabs>
		<booking-info :ruleVisible.sync="infoDialogVisible" @booking="userActivity" :max-num="1" :flag="false"></booking-info>
    </div>
</template>
<script>
import { getActivityDetails, addActivity, delActivity } from '@/api';
import { debounce } from '@/utils/utils.js';
	import bookingInfo from './bookingInfo.vue'
	import evaludateList from './evaluateList.vue'
export default {
	components:{bookingInfo, evaludateList},
    data() {
        return {
            info: {},
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '活动',
                },
                {
                    title: '精彩活动',
					to: {path: '/excitingActivities'}
                },
                {
                    title: '活动预约',
                },
            ],
            // statusArr: ['即将开始', '进行中', '已结束'], //  0未开始，1进行中，2已结束
			statusArr: ['未开始', '即将开始', '进行中', '已结束'],
            options: [
                {
                    name: '全部',
                    value: '',
                },
                {
                    name: '演出',
                    value: 0,
                },
                {
                    name: '讲座',
                    value: 1,
                },
                {
                    name: '展览',
                    value: 2,
                },
                {
                    name: '会议',
                    value: 3,
                },
            ],
			reportList:[],
			searchValue:'',
			tabs:'0',
			infoDialogVisible: false
        };
    },
	watch: {
		searchValue(){
			debounce(()=>{
				this.reportList = [];
				this.getReportList();
			})
		}
	},
    methods: {
        getActivityDetails() {
            getActivityDetails(this.$route.query.id).then((res) => {
                console.log(res);
                this.info = res.data;
                // if (res.code === 200) {
                //     this.data = res.data;
                // }
            });
        },
        collection() {
            if (this.info.collectionId == null) {
                this.$hao.addPraiseCollection({ relatedId: this.info.id, type: 1 }).then((res) => {
                    this.$message.success('收藏成功');
                    console.log(res);
                    this.info.collectionId = res.data.id;
                });
            } else {
                this.$hao.delPraiseCollection(this.info.collectionId).then(() => {
                    this.$message.success('取消收藏成功');
                    this.info.collectionId = null;
                });
            }
            this.$forceUpdate();
        },
        // 点赞
        praise() {
            if (this.info.praiseId === null) {
                this.$hao.addPraiseCollection({ relatedId: this.info.id, type: 5 }).then((res) => {
                    this.$message.success('点赞成功');
                    this.info.praiseId = res.data.id;
                });
            } else {
                this.$hao.delPraiseCollection(this.info.praiseId).then(() => {
                    this.$message.success('取消点赞成功');
                    this.info.praiseId = null;
                });
            }
        },
		openInfoDialog(){
			if (this.info.status !== 0) {
			    this.$message.warning('活动已结束');
			    return;
			}
			this.infoDialogVisible = true;
		},
        userActivity(info={}) {
            if (this.info.status !== 0) {
                this.$message.warning('活动报名已结束');
                return;
            }
			this.infoDialogVisible = false;
            if (this.info.userActivityId === null) {
                addActivity({ activityId: this.info.id, ...info }).then((res) => {
                    this.$message.success('报名成功');
                    this.info.userActivityId = res.data.id;
                });
            } else {
                delActivity(this.info.userActivityId).then((res) => {
                    this.$message.success('取消报名成功');
                    this.info.userActivityId = null;
                });
            }
        },
		initTypeDictData(){
			this.$hao.getDictDataByType("sys_activity_type").then((res) => {
			    this.options = res.data;
			});
		},
		getReportList() {
			let data={pageNum:1,pageSize:1000,activityId:this.$route.query.id};
			data.searchValue = this.searchValue.trim();
			this.$hao.listUserActivityFile(data).then(res => {
				this.reportList = res.rows.filter(ele => {
					return !!ele.fileUrl;
				});
			});
		},
    },
    created() {
        this.getActivityDetails();
		this.initTypeDictData();
		this.getReportList();
    }
};
</script>
<style scoped lang="less">
	ul {
		list-style: none;
	}
	/deep/img {
		max-width: 100% !important;
	}
.top {
    display: flex;
    .left {
        width: 580px;
        height: 385px;
        border-radius: 10px;
    }
    .right {
        flex: 1;
        margin-left: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title {
            font-weight: 700;
            font-size: 20px;
            color: #333333;
            margin-bottom: 8px;
        }
        .tips {
            display: flex;
            margin: 24px 0 36px 0;
            .tip {
                background: #f5f5f5;
                padding: 2px 12px;
                margin-right: 8px;
                font-size: 13px;
                line-height: 19px;
                color: #f33f3e;
                flex: none;
                flex-grow: 0;
                background: rgba(243, 63, 62, 0.1);
                border-radius: 2px;
                order: 1;
            }
        }
        .item {
            display: flex;
            align-items: center;
            margin-top: 16px;
            img {
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }
            span {
                font-size: 16px;
                color: #333333;
                flex: none;
                order: 1;
                flex-grow: 0;
            }
        }
        .butts {
            display: flex;
            align-items: flex-end;
            .butt {
                padding: 12px 110px;
                background: #eda200;
                box-shadow: 0 2px 4px rgba(14, 79, 71, 0.2);
                border-radius: 4px;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                color: #ffffff;
                flex: none;
                order: 0;
                flex-grow: 0;
                margin-top: 37px;
                width: max-content;
            }
            .butt2 {
                padding: 12px 110px;
                background: #e6e6e6;
                box-shadow: 0 2px 4px rgba(14, 79, 71, 0.2);
                border-radius: 4px;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                color: #ffffff;
                flex: none;
                order: 0;
                flex-grow: 0;
                margin-top: 37px;
                width: max-content;
            }
            .butt1 {
                display: flex;
                flex-direction: column;
                margin-left: 24px;
                align-items: center;
                img {
                    width: 20px;
                    height: 20px;
                    margin-bottom: 2px;
                }
                span {
                    font-size: 12px;
                    line-height: 17px;
                    color: #666666;
                    flex: none;
                    order: 1;
                    flex-grow: 0;
                }
            }
            .butt3 {
                padding: 12px 110px;
                background: #DCDCDC;
                box-shadow: 0 2px 4px rgba(14, 79, 71, 0.2);
                border-radius: 4px;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                color: #ffffff;
                flex: none;
                order: 0;
                flex-grow: 0;
                margin-top: 37px;
                width: max-content;
            }
        }
    }
}
.titles {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: #333333;
    margin: 24px 0 12px 0;
}
.details {
    font-size: 16px;
    line-height: 23px;
    color: #666666;
}

.details:last-child {
    margin-bottom: 150px;
}
	a {
		text-decoration: none;
		color: #000;
	}

	.report-file {
		font-size: 20px;
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		line-height: 20px;
		margin-bottom: 15px;
	}
	.report-file img {
		background-color: #eda200;
		width: 20px;
		vertical-align: middle;
		margin-right: 10px;
		border-radius: 3px;
	}
	.report-file a:hover {
		color: #eda200;
		text-shadow: 0 1px 1px rgba(237,162,0,0.2);
		cursor: pointer;
	}
	ul li {
		padding: 20px;
		border-bottom: 1px dashed #666;
	}
	// ul li:last-child {
	// 	border: 0;
	// }
	.report-info {
		color: #999;
		padding-left: 30px;
	}
	.report-info span:first-of-type {
		margin-right: 10px;
	}
</style>
